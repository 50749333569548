import NextImage from 'next/image'

import { Button } from 'components/ui'
import { getValidIcon } from 'components/ui/Icon'
import { DisplayHeaderStoryblok } from 'lib/storyblok/types'
import {
  getAnchorFromCmsLink,
  getStoryblokImageAttributes,
} from 'lib/utils/content'

type DisplayHeaderProps = {
  block: DisplayHeaderStoryblok
}

export const DisplayHeader = ({ block }: DisplayHeaderProps) => {
  const { headline, ctas, images } = block

  return (
    <div className="bg-background mx-auto max-w-screen-desktop px-5 lg:px-20 py-10 lg:py-15">
      <div className="p-5 lg:p-8 bg-foreground/5 rounded-6 border border-foreground/5">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-8">
          {headline && (
            <div className="flex flex-col justify-between">
              <div className="text-foreground text-title-large lg:text-title font-regular">
                {headline}
              </div>

              {ctas && (
                <div className="flex flex-col lg:flex-row gap-4 lg:p-0 pt-15 pb-10 w-[177px] lg:w-auto">
                  {ctas.map((cta, i) => {
                    const { _uid, icon, label, alternative_text, link } = cta
                    const { href, target, rel } = getAnchorFromCmsLink(link)
                    const typedIcon = getValidIcon(icon)

                    return (
                      <Button
                        as="a"
                        key={_uid}
                        rel={rel}
                        href={href}
                        target={target}
                        icon={typedIcon}
                        alternativeText={alternative_text}
                        variant={i === 0 ? 'secondary' : 'primary'}
                      >
                        {label}
                      </Button>
                    )
                  })}
                </div>
              )}
            </div>
          )}

          {images && images.length === 3 && (
            <div
              className={`
                grid 
                grid-cols-[0.51fr_0.25fr_0.24fr] 
                lg:grid-cols-[0.38fr_0.34fr_0.28fr] 
                grid-rows-[0.38fr_0.62fr] 
                lg:grid-rows-[0.52fr_0.17fr_0.31fr] 
                gap-[14.35px] 
                lg:gap-5 
                lg:w-[625px] 
                h-[265px] 
                lg:h-[300px]
              `}
            >
              <div className="rounded-5 bg-[linear-gradient(128deg,_hsl(var(--twc-background-secondary-advice)),_hsl(var(--twc-background-secondary-preservation)))]" />

              <div className="rounded-tr-5 rounded-bl-5 relative overflow-hidden col-span-2 lg:col-span-1">
                <NextImage
                  fill
                  sizes="200"
                  alt={getStoryblokImageAttributes(images[0])?.alt}
                  src={getStoryblokImageAttributes(images[0])?.src}
                  className="pointer-events-none flex-shrink-0 object-cover"
                />
              </div>

              <div
                className="
                  relative overflow-hidden 
                  col-span-2 lg:col-span-1 lg:row-span-2
                  rounded-tr-5 rounded-bl-5 
                  lg:rounded-tr-none lg:rounded-bl-none 
                  lg:rounded-tl-5 lg:rounded-br-5 
                  lg:rounded-tr-0
                "
              >
                <NextImage
                  className="pointer-events-none flex-shrink-0 object-cover"
                  src={getStoryblokImageAttributes(images[1])?.src}
                  alt={getStoryblokImageAttributes(images[1])?.alt}
                  fill
                  sizes="200"
                />
              </div>

              <div className="hidden lg:block rounded-tr-5 rounded-bl-5 relative overflow-hidden col-span-2 row-span-2">
                <NextImage
                  className="pointer-events-none flex-shrink-0 object-cover"
                  src={getStoryblokImageAttributes(images[2])?.src}
                  alt={getStoryblokImageAttributes(images[2])?.alt}
                  fill
                  sizes="200"
                />
              </div>
              <div className="rounded-5 bg-[linear-gradient(128deg,#f0e7b2,#ebd3c5)]" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
